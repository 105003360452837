@import "variables";

* {
    box-sizing: border-box;
}

::-webkit-scrollbar {
    width: 4px;
    margin-left: 1rem;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #0000001a;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px #000000;
}

html {
    height: 100%;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    line-height: 1.4rem;
}

body {
    background: url(../assets/background.png) no-repeat center center $brand;
    display: block;
    height: 100%;
    margin: 0;
    color: #747579;
    padding: 0;
    overflow: hidden;

    @media (max-width: 800px) {
        &.not-centred {
            overflow: auto;
            padding-bottom: 2rem;
            padding-top: 2rem;
            height: auto;
        }
    }

    a {
        text-decoration: none;
        color: #2196f3;
    }
}

button {
    appearance: none;
    cursor: pointer;
    border: none;
    background: transparent;
}

select {
    appearance: none;
}

.l-main-container {
    align-items: center;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 11px 19px 0px #0000001a;
    display: flex;
    justify-content: center;
    max-width: 800px;
    padding: 4rem 3rem;
    width: 90%;

    @media (max-width: 800px) {
        padding: 1rem;
    }
}

.title {
    color: #1e1e1e;
    font-size: 1rem;
    font-weight: 500;
    margin: 0;
    text-align: center;

    &--large {
        font-size: 1.42rem;
        line-height: 1.25em;
        margin-bottom: 3.5rem;

        @media (max-width: 800px) {
            margin-bottom: 1.5rem;
        }
    }
}

.form-label {
    color: #1e1e1e;
    font-size: 1.14rem;
    font-weight: 500;
    margin: 0;
    display: block;
    width: 100%;
}

.form-input {
    background: #fff;
    border-radius: 4px;
    border: 1px solid #ccd2e0;
    height: 32px;
    margin-bottom: 1rem;
    font-size: 1rem;
    padding: 0 1rem;
    width: 100%;

    &--dropdown {
        color: #747579;
    }
}

.btn {
    appearance: none;
    cursor: pointer;
    display: block;
    filter: grayscale(0);
    font-size: 1rem;
    height: 32px;
    opacity: 1;
    transition: all ease-in-out 0.25s;

    &:disabled {
        opacity: 0.4;
        cursor: default;
        filter: grayscale(1);
    }

    &--primary {
        background: #2196f3;
        border-radius: 50px;
        border: 1px solid #2196f3;
        color: #fff;
        font-weight: 500;
        margin: 0 auto;
        padding: 0 2rem;
        word-break: keep-all;

        &:hover:enabled {
            background: #fff;
            border: 1px solid #ccd2e0;
            color: #2196f3;

            &:disabled {
                background: #2196f3;
                border: 1px solid #2196f3;
                color: #fff;
            }
        }
    }

    &--secondary {
        background: #fff;
        border-radius: 50px;
        border: 1px solid #ccd2e0;
        color: #2196f3;
        font-weight: 500;
        margin: 0 auto;
        padding: 0 2rem;

        &:hover:enabled {
            background: #2196f3;
            border-color: #2196f3;
            color: #fff;
        }
    }

    &--tertiary {
        background: #fff;
        border-radius: 4px;
        border: 1px solid #ccd2e0;
        color: #1e1e1e;
        margin: 0;
        padding: 0 1rem;

        &:hover:enabled {
            background: #2196f3;
            border-color: #2196f3;
            color: #fff;
        }

        &.active {
            background: #2196f3;
            border-color: #2196f3;
            color: #fff;
        }
    }

    &--warning {
        background: #f44336;
        border-radius: 50px;
        border: 1px solid #f44336;
        color: #fff;
        font-weight: 500;
        margin: 0 auto;
        padding: 0 2rem;

        &:hover:enabled {
            background: #fff;
            border: 1px solid #ccd2e0;
            color: #f44336;

            &:disabled {
                background: #f44336;
                border: 1px solid #f44336;
                color: #fff;
            }
        }
    }
}

.mat-mdc-tooltip {
    .mdc-tooltip__surface.mdc-tooltip__surface-animation {
        background: #3D4043;
    }
}
